/**
 * Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.container {
    font-size: 12px;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
}

.links {
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
    color: var(--mui-palette-primary-main);
}
