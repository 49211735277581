/**
 * Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.container {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 3rem;
    padding: 1rem 2rem;
    align-items: center;
    height: var(--header-height);
    background-color: var(--header-background-color);
    border-bottom: 1px solid rgba(145, 158, 171, 0.24);
}

.logo {
    width: 150px;
    height: 40px;
    cursor: pointer;
    fill: var(--logo-color);
}

.userOptions {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.organizationSelect {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    max-width: 650px;
}

.addOrganizationButton {
    color: var(--mui-palette-text-primary);
}

.addOrganizationButton:hover {
    background: none;
}
