/**
 * Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.purchaseInfo {
    display: grid;
}

.purchaseAction {
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 8px;
}

.seatsInput {
    width: 80px;
}

.seatsInputField {
    background-color: white;
}

.teamTrialPlan {
    background-color: var(--blue-card-theme);
}

[data-mui-color-scheme="light"] .teamTrialPlan {
    background-color: #007AFF1A;
}

[data-mui-color-scheme="dark"] .teamTrialPlan {
    background-color: #71B5FF1A;
}

[data-mui-color-scheme="light"] .teamPlan {
    background-color: #c5cae9;
}

[data-mui-color-scheme="dark"] .teamPlan {
    background-color: #9fa8da;
}

[data-mui-color-scheme="light"] .teamPlanExpired,
[data-mui-color-scheme="light"] .teamTrialPlanExpired {
    background-color: #ef9a9a;
}

[data-mui-color-scheme="dark"] .teamPlanExpired,
[data-mui-color-scheme="dark"] .teamTrialPlanExpired {
    background-color: #ef5350;
}

.subscriptionRenewal {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
}

.subscriptionInfo td {
    width: 13rem;
}
