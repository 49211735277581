/**
 * Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.chip {
    border-radius: 6px;
    padding: 0px 6px;
}

.chip[data-status="open"] {
    background-color: var(--ongoing-issue-color);
    color: white;
}

.chip[data-status="closed"] {
    background-color: var(--closed-issue-color);
    color: white;
}
