/**
 * Copyright (C) 2023 Explore.dev, Unipessoal Lda - All Rights Reserved
 * Use of this source code is governed by a license that can be
 * found in the LICENSE file.
 */

.container {
    background-image: url('../assets/reviewpadBackground.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    gap: 2rem;
}

.container>svg {
    fill: white;
    max-width: 250px;
    height: 60px;
}

.loginContainer {
    background-color: white;
    padding: 20px 30px;
    border-radius: 6px;
    display: grid;
    gap: 1rem;
}

.loginProviders {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.loginProviders>button {
    border: none;
    padding: 10px;
    cursor: pointer;
    box-shadow: none;
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    width: 100%;
}

.loginProviders>button:hover {
    box-shadow: 0 10px 14px 0 var(--grey-100-color);
    transition: box-shadow 0.3s ease-in-out;
}

.loginProviders>button>svg {
    width: 25px;
    height: 25px;
}

.gitHubSignInButton {
    background-color: #6e7581;
    color: white;
    border-radius: 6px;
    padding: 0 20px;
}

.gitHubSignInButton>svg {
    fill: white;
}
